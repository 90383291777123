import { IHouse } from '../types'
// Импорт иконок
import animalsIcon from '../images/icons/animals.png'
import bathroomIcon from '../images/icons/bathroom.png'
import bouquetIcon from '../images/icons/bouquet.png'
import cabletvIcon from '../images/icons/cabletv.png'
import kidsIcon from '../images/icons/kids.png'
import microwaveIcon from '../images/icons/microwave.png'
import musicIcon from '../images/icons/music.png'
import owenIcon from '../images/icons/owen.png'
import parkingIcon from '../images/icons/parking.png'
import partyIcon from '../images/icons/party.png'
import refrigeratorIcon from '../images/icons/refrigerator.png'
import tvIcon from '../images/icons/tv.png'
import waterheaterIcon from '../images/icons/waterheater.png'
import wifiIconIcon from '../images/icons/wi-fi.png'
import washingMachineIcon from '../images/icons/washing_machine.png'
import jacuzziIcon from '../images/icons/jacuzzi.png'
import balconIcon from '../images/icons/balcon.png'

// Фотографии домов
// Дом 1
import houseOnePhoto1 from '../images/houses/1/1.jpg'
import houseOnePhoto2 from '../images/houses/1/2.jpg'
import houseOnePhoto3 from '../images/houses/1/3.jpg'
import houseOnePhoto4 from '../images/houses/1/4.jpg'
import houseOnePhoto5 from '../images/houses/1/5.jpg'
import houseOnePhoto6 from '../images/houses/1/6.jpg'
import houseOnePhoto7 from '../images/houses/1/7.jpg'
import houseOnePhoto8 from '../images/houses/1/8.jpg'
import houseOnePhoto9 from '../images/houses/1/9.jpg'
import houseOnePhoto10 from '../images/houses/1/10.jpg'
import houseOnePhoto11 from '../images/houses/1/11.jpg'
// Дом 2
import houseTwoPhoto1 from '../images/houses/2/1.jpg'
import houseTwoPhoto2 from '../images/houses/2/2.jpg'
import houseTwoPhoto3 from '../images/houses/2/3.jpg'
import houseTwoPhoto4 from '../images/houses/2/4.jpg'
import houseTwoPhoto5 from '../images/houses/2/5.jpg'
// Дом 3
import houseThreePhoto1 from '../images/houses/3/1.jpg'
import houseThreePhoto2 from '../images/houses/3/2.jpg'
import houseThreePhoto3 from '../images/houses/3/3.jpg'
import houseThreePhoto4 from '../images/houses/3/4.jpg'
// Дом 4
import houseFourPhoto1 from '../images/houses/4/1.jpg'
import houseFourPhoto2 from '../images/houses/4/2.jpg'
import houseFourPhoto3 from '../images/houses/4/3.jpg'
// Дом 5
import houseFivePhoto1 from '../images/houses/5/1.jpg'
import houseFivePhoto2 from '../images/houses/5/2.jpg'
import houseFivePhoto3 from '../images/houses/5/3.jpg'
import houseFivePhoto4 from '../images/houses/5/4.jpg'
import houseFivePhoto5 from '../images/houses/5/5.jpg'
import houseFivePhoto6 from '../images/houses/5/6.jpg'
import houseFivePhoto7 from '../images/houses/5/7.jpg'
import houseFivePhoto8 from '../images/houses/5/8.jpg'
import houseFivePhoto9 from '../images/houses/5/9.jpg'
import houseFivePhoto10 from '../images/houses/5/10.jpg'
import houseFivePhoto11 from '../images/houses/5/11.jpg'
import houseFivePhoto12 from '../images/houses/5/12.jpg'
import houseFivePhoto13 from '../images/houses/5/13.jpg'


// Информация о домах
export const houses: IHouse[] = [
  // ДОМ 1
  {
    title: 'Дом №1',
    address: '',
    shortDescription: () => (
      // Описание дома. Можно использовать теги html
      <>
        <p>
          Это описание дома. Здесь можно использовать html теги. Это описание дома. Здесь можно
          использовать html теги. Это описание дома. Здесь можно использовать html теги. Это
          описание дома. Здесь можно использовать html теги.
        </p>
      </>
    ),
    prices: {
      weekday: 4500,
      weekend: 7000,
      month: 100000
    },
    mainFeatures: [
      {
        title: 'Площадь',
        count: "110 кв. м." ,
      },
      {
        title: 'Комнат',
        count: 3,
      },
      {
        title: 'Спальных мест',
        count: '2+2+2',
      },
      {
        title: 'Этажей',
        count: 2,
      },
    ],
    features: [
      // характеристики, которые есть в этом доме через запятую. Полный список в hotelFeatures ниже
      'wifi',
      'kids',
      'owen',
      'waterheater',
      'tv',
      'refrigerator',
      'microwave',
      'cabletv',
      'washingMachine',
      'balcon',
      'parking',
    ],
    photos: [
      houseOnePhoto1,
      houseOnePhoto2,
      houseOnePhoto3,
      houseOnePhoto4,
      houseOnePhoto5,
      houseOnePhoto6,
      houseOnePhoto7,
      houseOnePhoto8,
      houseOnePhoto9,
      houseOnePhoto10,
      houseOnePhoto11,
    ],
    // описание на блока HousesList
    maxPerson: 5,
    secondDescription: () => (
      // Второе описание дома. Можно использовать теги html
      <>
        <p>
        Стоимость указана для проживания одной семьи. За дополнительного гостя - 1000 руб./сутки
          Бронирование от 2 дней.
        </p>
        <ul>
          <li>Заезд с 16:00</li>
          <li>Выезд до 13:00</li>
        </ul>
        <p>*на территории базы есть баня, можно забронировать дополнительно</p>
        <p>*Сентябрь - март, за исключением новогодних праздников, скидка на проживание 20%</p>
      </>
    ),
  },

  // ДОМ 2
  {
    title: 'Дом №2',
    address: '',
    shortDescription: () => (
      // Описание дома. Можно использовать теги html
      <>
        <p>
          Это описание дома. Здесь можно использовать html теги. Это описание дома. Здесь можно
          использовать html теги. Это описание дома. Здесь можно использовать html теги. Это
          описание дома. Здесь можно использовать html теги.
        </p>
      </>
    ),
    prices: {
      weekday: 5000,
      weekend: 7000,
      month: 110000
    },
    mainFeatures: [
      {
        title: 'Площадь',
        count: "120 кв. м." ,
      },
      {
        title: 'Комнат',
        count: 4,
      },
      {
        title: 'Спальных мест',
        count: '2+2+2',
      },
      {
        title: 'Этажей',
        count: 2,
      },
    ],
    features: [
      // характеристики, которые есть в этом доме через запятую. Полный список в hotelFeatures ниже
      'wifi',
      'kids',
      'owen',
      'waterheater',
      'tv',
      'refrigerator',
      'microwave',
      'cabletv',
      'washingMachine',
      'balcon',
      'parking',
    ],
    photos: [
      houseTwoPhoto1,
      houseTwoPhoto2,
      houseTwoPhoto3,
      houseTwoPhoto4,
      houseTwoPhoto5,
    ],
    // описание на блока HousesList
    maxPerson: 6,
    secondDescription: () => (
      // Второе описание дома. Можно использовать теги html
      <>
        <p>
        Стоимость указана для проживания одной семьи. За дополнительного гостя - 1000 руб./сутки
          Бронирование от 2 дней.
        </p>
        <ul>
          <li>Заезд с 16:00</li>
          <li>Выезд до 13:00</li>
        </ul>
        <p>*на территории базы есть баня, можно забронировать дополнительно</p>
        <p>*Сентябрь - март, за исключением новогодних праздников, скидка на проживание 20%</p>
      </>
    ),
  },

  // ДОМ 3
  {
    title: 'Дом №3',
    address: '',
    shortDescription: () => (
      // Описание дома. Можно использовать теги html
      <>
        <p>
          Это описание дома. Здесь можно использовать html теги. Это описание дома. Здесь можно
          использовать html теги. Это описание дома. Здесь можно использовать html теги. Это
          описание дома. Здесь можно использовать html теги.
        </p>
      </>
    ),
    prices: {
      weekday: 6000,
      weekend: 8000,
      month: 130000
    },
    mainFeatures: [
      {
        title: 'Площадь',
        count: "150" ,
      },
      {
        title: 'Комнат',
        count: 5,
      },
      {
        title: 'Спальных мест',
        count: '2+2+2',
      },
      {
        title: 'Этажей',
        count: 2,
      },
    ],
    features: [
      // характеристики, которые есть в этом доме через запятую. Полный список в hotelFeatures ниже
      'wifi',
      'kids',
      'owen',
      'waterheater',
      'tv',
      'refrigerator',
      'microwave',
      'cabletv',
      'washingMachine',
      'balcon',
      'parking',
    ],
    photos: [
      houseThreePhoto1,
      houseThreePhoto2,
      houseThreePhoto3,
      houseThreePhoto4,
    ],
    // описание на блока HousesList
    maxPerson: 8,
    secondDescription: () => (
      // Второе описание дома. Можно использовать теги html
      <>
        <p>
        Стоимость указана для проживания одной семьи. За дополнительного гостя - 1000 руб./сутки
          Бронирование от 2 дней.
        </p>
        <ul>
          <li>Заезд с 16:00</li>
          <li>Выезд до 13:00</li>
        </ul>
        <p>*на территории базы есть баня, можно забронировать дополнительно</p>
        <p>*Сентябрь - март, за исключением новогодних праздников, скидка на проживание 20%</p>
      </>
    ),
  },

  // ДОМ 4
  {
    title: 'Дом №4',
    address: '',
    shortDescription: () => (
      // Описание дома. Можно использовать теги html
      <>
        <p>
          Это описание дома. Здесь можно использовать html теги. Это описание дома. Здесь можно
          использовать html теги. Это описание дома. Здесь можно использовать html теги. Это
          описание дома. Здесь можно использовать html теги.
        </p>
      </>
    ),
    prices: {
      weekday: 5000,
      weekend: 7000,
      month: 120000
    },
    mainFeatures: [
      {
        title: 'Площадь',
        count: "145 кв. м." ,
      },
      {
        title: 'Комнат',
        count: 5,
      },
      {
        title: 'Спальных мест',
        count: '2+2+2+2',
      },
      {
        title: 'Этажей',
        count: 2,
      },
    ],
    features: [
      // характеристики, которые есть в этом доме через запятую. Полный список в housesFeatures ниже
      'wifi',
      'kids',
      'owen',
      'waterheater',
      'tv',
      'refrigerator',
      'microwave',
      'cabletv',
      'washingMachine',
      'balcon',
      'parking',
    ],
    photos: [
      houseFourPhoto1,
      houseFourPhoto2,
      houseFourPhoto3,
    ],
    // описание на блока HousesList
    maxPerson: 6,
    secondDescription: () => (
      // Второе описание дома. Можно использовать теги html
      <>
        <p>
          Стоимость указана для проживания одной семьи. За дополнительного гостя - 1000 руб./сутки
          Бронирование от 2 дней.
        </p>
        <ul>
          <li>Заезд с 16:00</li>
          <li>Выезд до 13:00</li>
        </ul>
        <p>*на территории базы есть баня, можно забронировать дополнительно</p>
        <p>*Сентябрь - март, за исключением новогодних праздников, скидка на проживание 20%</p>
      </>
    ),
  },
    // ДОМ 5
    {
      title: 'Дом №5',
      address: '',
      shortDescription: () => (
        // Описание дома. Можно использовать теги html
        <>
          <p>
            Это описание дома. Здесь можно использовать html теги. Это описание дома. Здесь можно
            использовать html теги. Это описание дома. Здесь можно использовать html теги. Это
            описание дома. Здесь можно использовать html теги.
          </p>
        </>
      ),
      prices: {
        weekday: 5500,
        weekend: 7000,
        month: 120000
      },
      mainFeatures: [
        {
          title: 'Площадь',
          count: "120 кв. м." ,
        },
        {
          title: 'Комнат',
          count: 4,
        },
        {
          title: 'Спальных мест',
          count: '2+2+2',
        },
        {
          title: 'Этажей',
          count: 2,
        },
      ],
      features: [
        // характеристики, которые есть в этом доме через запятую. Полный список в housesFeatures ниже
        'wifi',
        'kids',
        'owen',
        'waterheater',
        'tv',
        'refrigerator',
        'microwave',
        'cabletv',
        'washingMachine',
        'balcon',
        'parking',
      ],
      photos: [
        houseFivePhoto1,
        houseFivePhoto2,
        houseFivePhoto3,
        houseFivePhoto4,
        houseFivePhoto5,
        houseFivePhoto6,
        houseFivePhoto7,
        houseFivePhoto8,
        houseFivePhoto9,
        houseFivePhoto10,
        houseFivePhoto11,
        houseFivePhoto12,
        houseFivePhoto13,
      ],
      // описание на блока HousesList
      maxPerson: 6,
      secondDescription: () => (
        // Второе описание дома. Можно использовать теги html
        <>
          <p>
            Стоимость указана для проживания одной семьи. За дополнительного гостя - 1000 руб./сутки
            Бронирование от 2 дней.
          </p>
          <ul>
            <li>Заезд с 16:00</li>
            <li>Выезд до 13:00</li>
          </ul>
          <p>*на территории базы есть баня, можно забронировать дополнительно</p>
          <p>*Сентябрь - март, за исключением новогодних праздников, скидка на проживание 20%</p>
        </>
      ),
    },
  // Сюда можно добавлять дома
]

// Все возможные харатеристики отеля
// [ wifi, party, music, waterheater, animals, tv, refrigerator, microwave, cabletv, bouquet, kids, owen, parking, bathroom, washingMachine, jacuzzi, balcon ]
export const housesFeatures = {
  wifi: {
    name: 'Интернет Wi-Fi',
    icon: wifiIconIcon,
  },
  party: {
    name: 'Для мероприятий',
    icon: partyIcon,
  },
  music: {
    name: 'Музыкальный центр',
    icon: musicIcon,
  },
  waterheater: {
    name: 'Водонагреватель',
    icon: waterheaterIcon,
  },
  animals: {
    name: 'Можно с животными',
    icon: animalsIcon,
  },
  tv: {
    name: 'Телевизор',
    icon: tvIcon,
  },
  refrigerator: {
    name: 'Холодильник',
    icon: refrigeratorIcon,
  },
  microwave: {
    name: 'СВЧ печь',
    icon: microwaveIcon,
  },
  cabletv: {
    name: 'Кабельное Телевидение',
    icon: cabletvIcon,
  },
  bouquet: {
    name: 'Для романтических встреч',
    icon: bouquetIcon,
  },
  kids: {
    name: 'Можно с детьми',
    icon: kidsIcon,
  },
  owen: {
    name: 'Кухонная плита',
    icon: owenIcon,
  },
  parking: {
    name: 'Парковка',
    icon: parkingIcon,
  },
  bathroom: {
    name: 'Ванные принадлежности',
    icon: bathroomIcon,
  },
  washingMachine: {
    name: 'Стиральная машина',
    icon: washingMachineIcon,
  },
  jacuzzi: {
    name: 'Джакузи',
    icon: jacuzziIcon,
  },
  balcon: {
    name: 'Балкон',
    icon: balconIcon,
  },
}
