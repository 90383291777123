import Header from '../Header/Header'
import HousesDetailList from '../HousesDetailList/HousesDetailList'
import HousesList from '../HousesList/HousesList'
import { infoDataOne, infoDataTwo } from '../../constants/InfoWithImages'
import InfoWithImages from '../InfoWithImages/InfoWithImages'
import Menu from '../Menu/Menu'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import './App.scss'
import Cover from '../Cover/Cover'
import Contacts from '../Contacts/Contacts'
import Footer from '../Footer/Footer'

function App() {
  return (
    <div className="app">
      <Menu />
      <Header />
      <HousesDetailList />
      <InfoWithImages data={infoDataOne} />
      <HousesList />
      <InfoWithImages data={infoDataTwo} />
      <PhotoGrid />
      <Cover />
      <Contacts />
      <Footer />
    </div>
  )
}

export default App
