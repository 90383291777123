import { IHouse } from '../../../types'
import { Navigation, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/effect-fade'
import 'swiper/scss/navigation'
import './House.scss'

interface IProps {
  house: IHouse
}

const House = ({ house }: IProps) => {
  return (
    <div className="house">
      <div className="house__img-overlay">
        <Swiper modules={[EffectFade, Navigation]} effect={'fade'} navigation>
          {house.photos.map((photoUrl) => (
            <SwiperSlide>
              <img src={photoUrl} alt={`Фото ${house.title}`} className="house__img" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="house__main-info">
        <h3 className="house__title">{house.title && house.title}</h3>

        <p>Комфортное размещение до {house.maxPerson} человек.</p>

        <ul className="house__prices">
          <li>Будни (вс-чт) - {house.prices.weekday} р.</li>
          <li>Пт, сб - {house.prices.weekend} р.</li>
          <li>Месяц - {house.prices.month} р.</li>
        </ul>

        <div>{house.secondDescription()}</div>

      </div>
    </div>
  )
}

export default House
