import { houses } from '../../constants/housesInfo'
import HouseDetail from './HouseDetail/HouseDetail'
import './HousesDetailList.scss'

const HousesDetailList = () => {
  return (
    <section className="section houses-detail-list">
      {houses.map((house, i) => (
        <HouseDetail house={house} key={`house-detail-${i}`} />
      ))}
    </section>
  )
}

export default HousesDetailList
