import { houses } from '../../constants/housesInfo'
import House from './House/House'
import './HousesList.scss'

const HousesList = () => {
  return (
    <section className="section houses-list" id="houses-list">
      <div className="houses-list__overlay">
        <h2 className="houses-list__title">Наши дома</h2>
        <p className="houses-list__text">
        Уютные новые домики площадью от 110 до 150 кв.м. на закрытой территории 1.5 га природоохранной зоны на окраине леса. Идиальное место для семей с детьми, новые дома со всеми удобствами и разной планировкой. Отопление, водоснабжение, канализация, Wi-Fi, телевидение,парковка, мангальная зона, гостевая беседка на 20 человек. Своё большое озеро, с прибрежной зоной и пляжем. Имея своё КТП, дизельный генератор на 100 КвТ, центральное отопление, водоснабжение и т.д., база может работать автономно, не завися от городского обеспечения.
        </p>
        {houses.map((house, i) => (
          <House house={house} key={`house-${i}`} />
        ))}
      </div>
    </section>
  )
}

export default HousesList
