import './Contacts.scss'

const Contacts = () => {
  return (
    <section className="contacts" id="contacts">
      <div className="contacts__text">
        <h2>Контакты:</h2>
        <a href="tel: +79991234567" className="contacts__tel">
          +7 (926)600-89-20
        </a>
        <a href="mailto: name@mail.ru" className="contacts__email">
          yarcevo31@mail.ru
        </a>
        <p>Московская Область, Дмитровский район, деревня Ярцево</p>
      </div>

      <div className="contacts__map">
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A2a9ed8654655b37e54e57f11e4c0478a8ea7d4e0152442a3039c7e3eb50c09e5&amp;source=constructor"
          width="100%"
          height="500"
          frameBorder="0"
        ></iframe>
      </div>
    </section>
  )
}

export default Contacts
