import './Header.scss'

const Header = () => {
  return (
    <header className="header">
      <div className="header__overlay">
        <h1 className="header__title">
          <span className='header__title-span'>База семейного отдыха</span>
          <span className='header__title-name'>ЯРЦЕВО</span>
        </h1>
        <p className="header__text">Дома в аренду в 45 километрах от Москвы</p>
      </div>
    </header>
  )
}

export default Header
