import { useEffect, useState } from 'react'
import { scrollTo } from '../../utils/utils'
import './Menu.scss'

const Menu = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  function handleToogleMenu(evt: any) {
    if (evt.target === evt.currentTarget) setIsOpenMenu(!isOpenMenu)
  }

  function checkScroll() {
    if (window.scrollY > 0) setIsScrolled(true)
    else setIsScrolled(false)
  }

  useEffect(() => {
    document.addEventListener('scroll', checkScroll)
    return () => {
      document.removeEventListener('scroll', checkScroll)
    }
  }, [])

  return (
    <section className={`menu${isOpenMenu ? ' menu_open' : ''}`} onClick={handleToogleMenu}>
      <div
        className={`menu__burger-btn-overlay${isOpenMenu ? ' menu__burger-btn-overlay_open' : ''}`}
      >
        <div
          className={`menu__burger-btn${isOpenMenu ? ' menu__burger-btn_open' : ''}`}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
      <div
        className={`
          menu__overlay${isOpenMenu ? ' menu__overlay_open' : ''}
          ${isScrolled ? ' menu__overlay_bg_dark' : ''}`}
      >
        <ul>
          <li onClick={() => scrollTo("about-hotel")}>О базе</li>
          <li onClick={() => scrollTo("houses-list")}>Дома</li>
          <li onClick={() => scrollTo("photo-grid")}>Услуги</li>
          <li onClick={() => scrollTo("contacts")}>Контакты</li>
        </ul>

      </div>
    </section>
  )
}

export default Menu
