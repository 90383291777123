import React from 'react'
import { hydrate, render } from 'react-dom'
import './index.scss'
import App from './components/App/App'

const rootElement = document.getElementById('root')
const APP = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
// @ts-ignore
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement)
} else {
  render(APP, rootElement)
}
