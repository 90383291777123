import './InfoWithImages.scss'

interface Props {
  data: {
    title: string
    description: string
    images: {
      one: any
      two: any
      three: any
    }
  }
}

const InfoWithImages = ({ data }: Props) => {
  return (
    <section className="section info-section" id="about-hotel">
      <h2 className="info-section__title">{data.title}</h2>
      <p className="info-section__description">{data.description}</p>
      <div className="info-section__gallery">
        <img src={data.images.one} alt="Фото" className="info-section__image" />
        <img src={data.images.two} alt="Фото" className="info-section__image" />
        <img src={data.images.three} alt="Фото" className="info-section__image" />
      </div>
    </section>
  )
}

export default InfoWithImages
