import infoOne1 from '../images/infoWithImage/infoOne-1.jpg'
import infoOne2 from '../images/infoWithImage/infoOne-2.jpg'
import infoOne3 from '../images/infoWithImage/infoOne-3.jpg'

import infoTwo1 from '../images/infoWithImage/infoTwo-1.jpg'
import infoTwo2 from '../images/infoWithImage/infoTwo-2.jpg'
import infoTwo3 from '../images/infoWithImage/infoTwo-3.jpg'

export const infoDataOne = {
  title: 'База семейного отдыха',
  description:
    'Идиальное место для семейного отдыха в деревне Ярцево Дмитровского городского округа Московской области. Новые дома на закрытой территории 1.5 га природоохранной зоны на окраине леса. (33 -й км.Рогочевского шоссе, 40 мин. до метро Ховрино). Своё большое озеро, с прибрежной зоной и пляжем. Имея своё КТП, дизельный генератор на 100 КвТ, центральное отопление, водоснабжение и т.д., база может работать автономно, не завися от городского обеспечения.',
  images: {
    one: infoOne1,
    two: infoOne2,
    three: infoOne3,
  },
}

export const infoDataTwo = {
  title: 'Идеальное место для вашего отдыха',
  description:
    'Для того, чтобы дети могли познать жизнь в деревне, рядом, на хозяйской территории, есть парники, грядки, плодовые деревья и кустарники, пчелиная пасека, мини зоопарк, где дети могут покормить кроликов, гусей, уток, кур, а также своё второе озеро для катания детей на моторной лодке. Развлечения: Рыбалка, сезонная охота всех видов, включая с лайками и русскими гончими, грибы, ягоды. Выход, выезд в лес на пикники с возможностью ночёвки в предоставленных палатках с беседкой, автодоме и баней на дровах. В двадцати минутной доступности: База отдыха Волен, Соручаны, гольфполя, ферма альпаки, страусиная ферма, частный зоопарк, зооферма, конно- спортивный комплекс и многое другое. Прогулки: Выезд на велосипедах, квадроцикле, баги, мотоциклах. В зимний период на лыжах, снегоходах.',
  images: {
    one: infoTwo1,
    two: infoTwo2,
    three: infoTwo3,
  },
}
