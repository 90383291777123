import './Cover.scss'

const Cover = () => {
  return (
    <section className="cover">
      <div className="cover__overlay">
        <h2 className="cover__title">Забронируйте дом</h2>
        <h2 className="cover__title">
          +7 926 600 89 20
        </h2>
      </div>
    </section>
  )
}

export default Cover
