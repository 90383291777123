import { useMemo, useRef, useState } from 'react'
import { IHouse } from '../../../types'
import { housesFeatures } from '../../../constants/housesInfo'
import Viewer from '../../Viewer/Viewer'
import './HouseDetail.scss'

interface IProps {
  house: IHouse
}

const HouseDetail = ({ house }: IProps) => {
  const dropRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenViewer, setIsOpenViewer] = useState(false)
  const [height, setHeight] = useState('0px')

  const handleOpen = () => {
    // @ts-ignore
    setHeight(isOpen ? '0px' : dropRef.current.scrollHeight)
    setIsOpen(!isOpen)
  }

  const currentHouseFeatures = useMemo(
    () =>
      house.features.map((featKey) => {
        // @ts-ignore
        if (Object.keys(housesFeatures).includes(featKey)) return housesFeatures[featKey]
        return null
      }),
    [house.features]
  )

  return (
    <div className="house-detail">
      <div className="house-detail__main-overlay">
        <div className="house-detail__img-overlay" onClick={() => setIsOpenViewer(true)}>
          <img src={house.photos[0]} alt={`Фото ${house.title}`} className="house-detail__img" />
          <p className="house-detail__img-caption">{house.photos.length} фото</p>
        </div>

        <div className="house-detail__main-info">
          <h3 className="house-detail__title">{house.title && house.title}</h3>
          <p className="house-detail__address">{house.address && house.address}</p>
          <ul className="house-detail__main-features-list">
            {house.mainFeatures.map((feat, i) => {
              return feat.title && feat.count ? (
                <li className="house-detail__main-feature" key={`main-feature-${i}`}>
                  {feat.title}: {feat.count}
                </li>
              ) : null
            })}
          </ul>

          <div className="house-detail__features-list">
            {currentHouseFeatures.map((featObj, i) => (
              <img
                key={`features-list-${i}`}
                className="house-detail__feature"
                src={featObj.icon}
                alt={featObj.name}
                title={featObj.name}
              />
            ))}
          </div>
        </div>

        <div className="house-detail__price-overlay">
          <p className="house-detail__price">{house.prices.weekend && house.prices.weekend}</p>
          <p>₽ в сутки</p>
          <p className="house-detail__price-month">{house.prices.month && house.prices.month}</p>
          <p>₽ в месяц</p>
        </div>
      </div>

      <div className="house-detail__drop" style={{ maxHeight: height }} ref={dropRef}>
        <ul className="house-detail__features-list-detailed">
          {currentHouseFeatures.map((featObj, i) => (
            <li key={`features-${i}`}>
              <img
                className="house-detail__feature"
                src={featObj.icon}
                alt={featObj.name}
                title={featObj.name}
              />
              <p>{featObj.name}</p>
            </li>
          ))}
        </ul>
        <div className="house-detail__short-description">
          {house.shortDescription && house.shortDescription()}
        </div>
        <div className="house-detail__description-all-houses">
          <p>40 минут от Москвы и вы на природе</p>
          <p>
            — Рядом лес
            <br />
            — Ферма
            <br />
            — Контактный зоопарк
            <br />
            — Личное озеро
            <br />
            — Каток
            <br />
          </p>
          <p>Что входит в стоимость домика?</p>
          <p>
            ✅ Бесплатный Wi-Fi на всей территории базы и в каждом домике
            <br />
            ✅ Парковка на территории базы отдыха
            <br />
            ✅ Зона барбекю
            <br />
            ✅ Ферма с контактным зоопарком
            <br />
            ✅ Детский городок
            <br />
            ✅ Пляж
            <br />
          </p>
          <p>
            Баня оплачивается отдельно. Стоимость -
            5000 руб/вечер (до 4 часов парения)
          </p>
        </div>
      </div>

      <div className="house-detail__buttons-overlay">
        <button className="house-detail__button-detail" onClick={handleOpen}>
          {isOpen ? 'Скрыть' : 'Подробнее'}
        </button>
      </div>

      <Viewer
        photoUrlArr={house.photos}
        isOpen={isOpenViewer}
        onClose={() => setIsOpenViewer(false)}
      />
    </div>
  )
}

export default HouseDetail
