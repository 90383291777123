import './PhotoGrid.scss'

const PhotoGrid = () => {
  return (
    <section className="section photo-grid" id="photo-grid">
      <div className="photo-grid__content">
        <h2>У нас есть всё, что вам нужно:</h2>
        <ul>
          <li>мангальный комплекс на территории каждого домика</li>
          <li>открытый каток</li>
          <li>настольный теннис (в летний период)</li>
          <li>парковка на территории домиков</li>
          <li>рыбалка, охота</li>
          <li>катание на лыжах</li>
          <li>прокат квадроциклов</li>
          <li>бесплатный Wi-Fi</li>
          <li>ферма с контактным зоопарком</li>
          <li>прекрасная территория для прогулок, с выходом в лес и к озерам</li>
        </ul>
      </div>

      <div className="photo-grid__pics">
        <div className="photo-grid__pic-1" />
        <div className="photo-grid__pic-2" />
        <div className="photo-grid__pic-3" />
        <div className="photo-grid__pic-4" />
      </div>
    </section>
  )
}

export default PhotoGrid
