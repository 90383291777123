import { memo, useState } from 'react'
// @ts-ignore
import ImgsViewer from 'react-images-viewer'

const Viewer = ({ photoUrlArr, isOpen, onClose }: any) => {
  const [currImgInd, setCurrImgInd] = useState<number>(0)

  const gotoNext = () => setCurrImgInd(currImgInd + 1)

  const gotoPrevious = () => setCurrImgInd(currImgInd - 1)

  const getImagesArr = () => photoUrlArr.map((url: string) => ({ src: url }))

  return (
    <ImgsViewer
      imgs={getImagesArr()}
      isOpen={isOpen}
      currImg={currImgInd}
      onClickPrev={gotoPrevious}
      onClickNext={gotoNext}
      onClose={onClose}
    />
  )
}

export default memo(Viewer)
